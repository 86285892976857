.container {
  background-color: var(--background-block-grey);
  width: 100%;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 64px 0;
}
