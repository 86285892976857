.container {
  display: grid;
  gap: 8px;
  //grid-template-columns: auto 1fr 130px 100px;
  grid-template-columns: auto 1fr 130px;

  align-items: center;

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr 130px;
  }

  p {
    margin: 0;
  }

  .linkContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    overflow: hidden;

    .copyButton {
      //color: var(--primary-grey);
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .link {
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--primary-blue)
    }
    @media screen and (max-width: 640px) {
      grid-column: 1 / 3;
    }
  }

  .date {
    @media screen and (max-width: 640px) {
      grid-column: 1 / 2;
    }
  }

  .statusBlock {
    @media screen and (max-width: 640px) {
      grid-column: 2 / 3;
    }
    display: flex;
    justify-content: flex-end;
  }
}
