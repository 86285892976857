.container {
    position: fixed;
    right: 20px;
}

.containerDesktop {
    width: 100px;
    height: 100px;
    bottom: 50px;
}

.containerMobile {
    width: 100px;
    height: 100px;
    bottom: 5px;
    right: 0;
}

.spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.uiProgressSpinner {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: inline-block;
}

.uiProgressSpinnerSvg {
    animation: uiProgressSpinnerRotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.uiProgressSpinnerCircle {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: 0;
    stroke: #d62d20;
    -webkit-animation:
        uiProgressSpinnerDash 1.5s ease-in-out infinite,
        uiProgressSpinnerColor 6s ease-in-out infinite;
    animation:
        uiProgressSpinnerDash 1.5s ease-in-out infinite,
        uiProgressSpinnerColor 6s ease-in-out infinite;
    strokelinecap: round;
}

@keyframes uiProgressSpinnerDash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes uiProgressSpinnerColor {
    0%,
    100% {
        stroke: #d62d20;
    }
    40% {
        stroke: #ffffff;
    }
    66% {
        stroke: #28ff96;
    }
    80%,
    90% {
        stroke: #ffa700;
    }
}

@keyframes uiProgressSpinnerRotate {
    100% {
        transform: rotate(5turn);
    }
}
