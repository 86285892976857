.hidden {
  opacity: 0;
  height: 0;
  width: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.inputFilesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 4px;

  background: var(--white);

  border: 1px dashed var(--secondary-light-grey);
  border-radius: 12px;

  cursor: pointer;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    min-height: 56px;

    .hideOnMobile {
      display: none;
    }
  }
  @media screen and (min-width: 768px) {
    min-height: 128px;

    .hideOnDesktop {
      display: none;
    }
  }
}

.dropArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: #0000002b;
}

.highlight {
  border-color: var(--primary-blue);
  background-color: var(--background-block-hover);
}

.inputFilesLabel {
  font-size: 14px;
  line-height: 1.71;
  text-align: center;
  color: var(--primary-blue);

  margin: 0;
  padding: 0;

  .inputFilesLabelPrimaryText {
    font-weight: 600;
  }
}

.inputFilesLabelDesktop {
  display: flex;
  flex-direction: column;
}


.inputFilesButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;

  background: var(--white);

  border: 1px solid var(--secondary-light-grey);
  border-radius: 8px;
}

.viewFilesList {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .listItem {
    position: relative;
    width: 118px;
    height: 122px;
    border-radius: 12px;
    overflow: hidden;

    &>p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 4px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .uploadedImage {
      object-fit: cover;
    }

    .removeImageButton {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      cursor: pointer;
      z-index: 2;
    }

    .bgSmallImage {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
