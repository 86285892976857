.scannerModal {
  width: 100%;
  min-height: 400px;
  //display: none;
}

.scannerModal2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0,0,0,0.3);

  canvas {
    //width: 100%;
    //height: 100%;
  }
}

.container {

  min-height: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  padding: 20px;

  height: 100%;
  width: 100%;

  .card {

    width: 100%;

    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;

      .scannerButton {
        @media screen and (min-width: 640px ){
          display: none;
        }
      }

      .inputBlock {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .titleOrderNumber {
          color: green;
        }

      }

      .trackNumberSection {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1rem;

        //@media screen and (min-width: 640px ){
        //  gap: normal;
        //}

      }

      .orderSection {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1rem;
      }
    }
  }

  .loader {
    height: 50px;
    width: 50px;
    //position: absolute;
    //z-index: 10;
  }

}
