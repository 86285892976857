@font-face {
    font-family: 'Montserrat';
    src:
        local('Montserrat'),
        url('../assets/fonts/Montserrat-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src:
        local('Montserrat'),
        url('../assets/fonts/Montserrat-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src:
        local('Montserrat'),
        url('../assets/fonts/Montserrat-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src:
        local('Montserrat'),
        url('../assets/fonts/Montserrat-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}
