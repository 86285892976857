.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .shadow1 {
    position: absolute;
    height: 41%;
    top: 0;
    width: 100%;
    z-index: 10;
    background-color: rgba(0,0,0,0.5);
  }

  .shadow2 {
    position: absolute;
    height: 41%;
    bottom: 0;
    width: 100%;
    z-index: 10;
    background-color: rgba(0,0,0,0.5);
  }

  .canvas {
    //width: 100%;
    max-width: 100%;
  }

  .video {
    max-width: 100%;
    width: 100%;
    //object-fit: cover;
  }

  .closeScanner {
    position: absolute;
    bottom: 0;
    left: 0;

    z-index: 11;
  }

}
