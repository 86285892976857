.container {
  background-color: var(--background-block-grey);
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
  }

  .title {
    //text-align: center;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: .15px;
    color: var(--primary-blue);

    span {
      color: var(--primary-red);
    }
  }

  .errorField {
    color: var(--primary-red);
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 16px;
  }
}



