.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  z-index: 10;
  box-shadow: 0 1px 3px 1px rgba(10, 36, 50, 0.12);

  p {
    margin: 0;
  }

  .container {
    height: 64px;
    display: grid;
    align-items: center;
    gap: 20px;
    grid-template-columns: auto 1fr auto auto;
    @media screen and (max-width: 640px) {
      grid-template-columns: auto 1fr auto;
    }
  }

  .username {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: var(--primary-grey);
    text-overflow: ellipsis;
    min-width: 0;
    overflow: hidden;
  }

  .logoutButton, .addButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .logoutButton {
    font-weight: 500;
    @media screen and (max-width: 640px) {
      gap: 0;
    }
  }

  .addButton {
    padding: 0 32px;
    font-weight: 700;

    @media screen and (max-width: 640px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 0;
    }
  }
}
