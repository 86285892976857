@import './_fonts.scss';
@import './colors.scss';
@import './bootstrap-grid.css';
@import './bootstrap-reboot.css';
@import '../../node_modules/animate.css/animate.css';
@import './toastify.css';
@import './minScreen768.scss';
@import './minScreen992.scss';
@import './maxScreen768.scss';
@import './maxScreen992.scss';
@import './maxScreen576.scss';
@import './maxScreen820.scss';
@import './minScreen820.scss';
@import '../../node_modules/react-tooltip/dist/react-tooltip.min.css';
@import '../../node_modules/react-loading-skeleton/dist/skeleton.css';

html, body, #root {
    width: 100%;
    height: 100%;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100%;
}

.w-auto {
    width: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.vw-100 {
    width: 100vw !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.h-0 {
    height: 0 !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mh-100 {
    max-height: 100% !important;
}

.vh-100 {
    height: 100vh !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.text-center {
    text-align: center;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: sticky !important;
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

.text-decoration-none {
    text-decoration: none;
    a {
        text-decoration: none;
    }
}

/*custom*/
.cursor-pointer {
    cursor: pointer;
}

.text-primary {
    color: var(--primary-blue);
}

.text-grey {
    color: var(--primary-grey);
}

.text-primary-black {
    color: var(--primary-black);
}

.is-success ~ .success-input {
    color: var(--primary-green);
    font-size: 12px;
    margin-left: 1px;
    transition: all 300ms ease-in-out !important;
}

.is-invalid ~ .error-input {
    color: var(--primary-red);
    font-size: 12px;
    margin-left: 1px;
    transition: all 300ms ease-in-out !important;
}

.is-invalid .selectField__control {
    border: 1px solid #e60033;
}

.hint-input {
    transition: all 300ms ease-in-out !important;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.mt16 {
    margin-top: 16px;
}

.mr16 {
    margin-right: 16px;
}

.mb0 {
    margin-bottom: 0;
}

.mb24 {
    margin-bottom: 24px;
}

.my24 {
    margin-top: 24px;
    margin-bottom: 24px;
}

.mx4 {
    margin-left: 4px;
    margin-right: 4px;
}

.wfc {
    width: min-content;
}

.dib {
    display: inline-block;
}

.p8 {
    padding: 8px;
}

.py8 {
    padding-top: 8px;
    padding-bottom: 8px;
}

.br12 {
    border-radius: 12px;
}

.fs13lh16 {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
}

.fs16lh20 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

a:visited,
a:link {
    color: var(--primary-blue);
}

.footPaddBlock {
    width: 100%;
    height: 58px;
}

.overflow-ellipsis-desktop {
    @media screen and (min-width: 768px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

html * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
html *::-webkit-scrollbar {
    display: none;
}

.animate-page {
    animation: animate-page 350ms linear;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: var(--secondary-grey);
    //width: 4px;
    border: 4px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
}

.custom-scroll::-webkit-scrollbar-track {
    background-color: var(--white);
    border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar {
    display: block;
    width: 11px;
    overflow: auto;
}

@keyframes animate-page {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.virtuosoListContainer [data-test-id='virtuoso-item-list'] {
    display: flex;
    flex-direction: column;
    gap: 4px;

    @media screen and (max-width: 1119px) {
        gap: 8px;
    }
}

@media screen and (max-width: 991px) {
    .hide-scrollbar-mobile {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .hide-scrollbar-mobile::-webkit-scrollbar {
        display: none;
    }
}

*[data-if='false'] {
    display: none !important;
}
