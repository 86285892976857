@media screen and (max-width: 991px) {
    .auth-form {
    }

    .d-none-992 {
        display: none;
    }
}
@media screen and (max-height: 767px) {
    .auth-form {
        height: calc(100% - 80px);
        overflow-y: auto;
    }
}
