.button {
    border-radius: 8px;
    border: none;
    transition: all 100ms ease-in-out;
    font-weight: 600;
}

.buttonEditContainer {
    @media (max-width: 600px) {
        width: 100%;
    }
}

.button:disabled {
    opacity: 0.6;
}

/*primary*/

.button[data-background='bg-primary'] {
    background-color: var(--primary-blue);
    color: white;
}

.button[data-background='bg-primary']:hover:not(:disabled) {
    @media (min-width: 600px) {
        background-color: var(--primary-red-hover);
    }
}

.button[data-background='bg-primary']:active:not(:disabled) {
    background-color: var(--primary-red-passed);
}

/*danger*/

.button[data-background='bg-danger'] {
    background-color: var(--primary-red);
    color: white;
}

.button[data-background='bg-danger']:hover:not(:disabled) {
    background-color: var(--primary-red-hover);
}

.button[data-background='bg-danger']:active:not(:disabled) {
    background-color: var(--primary-red-passed);
}

/*outline*/

.button[data-background='bg-outline'] {
    color: var(--primary-black);
    border: 1px solid var(--secondary-grey);
    background-color: var(--white);
}

.button[data-background='bg-outline']:hover {
    background-color: var(--background-item-hover-2);
}

.button[data-background='bg-outline-grey'] {
    color: var(--primary-black);
    border: 1px solid var(--secondary-grey);
}

/*green*/

.button[data-background='bg-green'] {
    color: white;
    background-color: var(--primary-green);
}

.button[data-background='bg-green']:hover:not(:disabled) {
    background-color: var(--primary-green-hover);
}

.button[data-background='bg-green']:active:not(:disabled) {
    background-color: var(--primary-green);
}

.button[data-size='large'] {
    height: 56px;
    padding: 0 24px;
    font-size: 18px;
    line-height: 24px;
}

.button[data-size='middle'] {
    height: 44px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 24px;
}

.button[data-size='premiddle'] {
    height: 40px;
    border-radius: 6px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 24px;
}

.button[data-size='small'] {
    height: 32px;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border-radius: 6px;
}
