.formInput {
    border-radius: 8px;
    border: 1px solid var(--secondary-grey);
    background: #ffffff !important;
    height: 44px;
    /*min-width: 281px;*/
    padding-top: 15px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
}

.formInput:-webkit-autofill {
    background: #ffffff !important;
    transition: background-color 5000s ease-in-out;
}

.formInputAutoFill[data-disable-fill-color='true'] {
    -webkit-text-fill-color: white;
}

.formInput:focus,
.formInput:focus-visible {
    border-color: var(--input-border-focus-color);
    outline: none;
}

.formInput:disabled {
    background-color: var(--input-disabled) !important;
}

.labelPlaceholder {
    font-size: 16px;
    position: absolute;
    left: 12px;
    top: 12px;
    color: var(--primary-grey);
    transition: all 200ms ease-in-out;
}

.labelPlaceholder[data-focus='true'] {
    font-size: 11px;
    top: 5px;
}

.isInvalid > input {
    border-color: var(--primary-red);
}

.isSuccess > input {
    border-color: var(--primary-green);
}
