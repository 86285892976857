.container {
    background: rgba(0, 0, 0, 0);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    visibility: hidden;
    transition: all 300ms ease-in-out;
    z-index: 20;
}
